import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatNumber } from "helpers/general";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import { TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS } from "helpers/constans";
import { Option } from "api/types/_public";
import CardSummary from "../Components/CardSummary";
import MediaApi from "api/mediaApi";
import classnames from "classnames";
import BreadCrumb from "components/Common/BreadCrumb";

const Revenue = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("All Type"),
    value: "",
  });
  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    service_type: formatSearch?.value,
  };
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [summaries, setSummaries] = useState<any>();
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG =
    TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("media.STT"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("media.Name"),
        accessor: "name",
      },
      {
        Header: "PV",
        accessor: "pv",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? formatNumber(cell.value) : "-"}
          </div>
        ),
      },
      {
        Header: "UV",
        accessor: "uv",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? formatNumber(cell.value) : "-"}
          </div>
        ),
      },
      {
        Header: (
          <div>
            <p className="m-0">{t("media.proceeds")}</p>
            <div className="d-flex">
              <p className="m-0" style={{ width: "50%" }}>
                Prc
              </p>
              {/* <p className="m-0" style={{ width: "33%" }}>
                News
              </p> */}
              <p className="m-0" style={{ width: "50%" }}>
                PR
              </p>
            </div>
          </div>
        ),
        accessor: "cmshub_revenue",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="d-flex">
            <div style={{ width: "50%" }} className="text-center">
              <p className="m-0">
                {cell?.value ? formatNumber(cell?.value) : "-"}
              </p>
            </div>
            {/* <div style={{ width: "33%" }} className="text-center">
              <p className="m-0">
                {cell?.original?.newshub_revenue
                  ? formatNumber(cell?.original?.newshub_revenue)
                  : "-"}
              </p>
            </div> */}
            <div style={{ width: "50%" }} className="text-center">
              <p className="m-0">
                {cell?.original?.prhub_revenue
                  ? formatNumber(cell?.original?.prhub_revenue)
                  : "-"}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: t("media.SumOfProfits"),
        accessor: "total_revenue",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? formatNumber(cell.value) : "-"}
          </div>
        ),
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    const [err, result] = await catchAsync(
      MediaApi.getRevenues(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    });
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      service_type: formatSearch?.value,
      search: keyword,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([
      moment(defaultParams?.start_date || "", "Y-MM-DD").toDate(),
      moment(defaultParams?.end_date || "", "Y-MM-DD").toDate(),
    ]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  const getSummaries = async () => {
    setLoadingSummaries(true);
    try {
      const [err, result] = await catchAsync(MediaApi.getRevenuesSummaries());

      if (err) {
        console.log(err);
        return;
      }
      setSummaries(result?.data);

      // const { items, pagination } = result?.data;
      // setRows(items);
      // setPagination(pagination);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  useEffect(() => {
    handleChangePage(0);
  }, [formatSearch]);

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("media.Revenue")}
            pageTitle={t("media.Management")}
          />
          <Card id="leadsList">
            <CardHeader className="border-0">
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                <div className="w-100">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                    <CardSummary
                      title="media.revenue.FullMedium"
                      value={summaries?.total || 0}
                      isLoading={loadingSummaries}
                    />

                    <CardSummary
                      title="media.revenue.CMSHubUsageMedia"
                      value={summaries?.total_cmshub || 0}
                      isLoading={loadingSummaries}
                    />

                    <CardSummary
                      title="media.revenue.MediaUsedByPRHub"
                      value={summaries?.total_prhub || 0}
                      isLoading={loadingSummaries}
                    />
                  </div>
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mt-2 mt-md-0 mb-2 mb-md-0"
                    >
                      <div
                        className="d-flex align-items-center date-picker-wrapper-custom"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">기간</div>
                        <div style={{ width: "300px" }}>
                          <DatePickerCustom
                            placeholder={`${t("A From Range")} - ${t(
                              "A To Range"
                            )}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            disabled={false}
                            isClearable={true}
                            onChangePicker={handleChangePicker}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mt-0">
                    <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "40px" }}
                      >
                        <div className="fw-bold text-nowrap">검색</div>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("cms_media.input_search")}...`}
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchCategory();
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                    >
                      <div className="d-flex gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={loading}
                          onClick={handleSearchCategory}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
                role="tablist"
              >
                {TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG.map(
                  (item, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={`cursor-pointer ${classnames({
                          active: formatSearch?.value === item?.value,
                        })}`}
                        onClick={() => {
                          setFormatSearch(item);
                        }}
                      >
                        {t(item?.label)}
                      </NavLink>
                    </NavItem>
                  )
                )}
              </Nav>
            </CardHeader>
            <CardBody className="pt-3">
              <div>
                <TableContainer
                  columns={columns}
                  data={rows || []}
                  customPageSize={pagination?.page_size}
                  customPageIndex={pagination?.page - 1}
                  totalRecords={pagination?.total}
                  customPageCount={Math.ceil(
                    pagination?.total / pagination?.page_size
                  )}
                  className="custom-header-css"
                  divClass="table-responsive table-card"
                  tableClass="align-middle"
                  theadClass="table-light align-middle"
                  handleChangePage={handleChangePage}
                  isLoading={loading}
                />
              </div>
              <ToastContainer closeButton={false} limit={1} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Revenue;
