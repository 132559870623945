/* eslint-disable react-hooks/exhaustive-deps */
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";

import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import {
  INQUIRY_SORT_BY,
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
  INQUIRY_STATUS,
  SERVICE_TYPE_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import { CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import classnames from "classnames";
import CardSummary from "pages/Media/Components/CardSummary";
import TableContainer from "components/Common/TableContainer";
import DatePickerCustom from "components/Common/DatePickerCustom";
import inquiryApi from "api/inquiryApi";
import { useModal } from "context/confirm.context";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import mediaApi from "api/mediaApi";
import ModalConfirmPoint from "./ModalConfirmPoint";
import ModalDetail from "./ModalDetail";
import FileViewer from "components/Common/fileViewer";
import BreadCrumb from "components/Common/BreadCrumb";
import { ROUTES } from "routes/allRoutes";
import MediaModal from "pages/Media/Media/ModalMedia";

const AcceptFileType = ["image/jpg", "application/pdf", "image/png", "image/jpeg"];

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const InquiryList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal } = useModal();
  const [loadingReply, setLoadingReply] = useState<boolean>(false);
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [index, setIndex] = useState<any>(-1);
  const [indexReply, setIndexReply] = useState<any>(-1);
  const [media, setMedia] = useState<any>();

  const SERVICE_TYPE_OPTIONS_LANG = SERVICE_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const SORT_BY_OPTIONS = INQUIRY_SORT_BY?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("All Type"),
    value: "0",
  });

  const [sortBy, setSortBy] = useState<Option | null>({
    label: t("inquiry.Newest_First"),
    value: "newest",
  });

  const [loading, setLoading] = useState(false);
  const [inquiryDetail, setInquiryDetail] = useState<any>();
  const [selectedRowID, setSelectedRowID] = useState<string>("");
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [loadingTotalWaiting, setLoadingTotalWaiting] = useState(false);
  const [totalWaiting, sesTotalWaiting] = useState<number>(0);
  const defaultParams = {
    page: 1,
    page_size: 10,
    created_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    created_to: moment().format("YYYY-MM-DD"),
    sort_by: "",
    service_type: "0",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [textReply, setTextReply] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<any>();
  const defaultRangeDate = [
    moment(queryParams?.created_from || "", "Y-MM-DD").toDate(),
    moment(queryParams?.created_to || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [choseFile, setChoseFile] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modalPoint, setModalPoint] = useState<boolean>(false);
  const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);
  const [isDeductPoints, setIsDeductPoints] = useState<boolean>(false);
  const [deductPoints, setDeductPoints] = useState<string | number>("0");

  const columns = useMemo(
    () => [
      {
        Header: t("inquiry.STT"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div
            className='text-center'
            style={{
              width: "60px",
            }}
          >
            <span>
              {pagination.total - pagination.page_size * (pagination.page - 1) - cell?.row?.index}
            </span>
          </div>
        ),
      },
      {
        Header: t("inquiry.Service"),
        accessor: "service_type",
        thClass: "text-center",
        Cell: (cell: any) => {
          let tagName: string = "";
          const servicesKeyType = new Map(
            TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
          );
          tagName += `${`<div class='tag-service mx-auto bg-${servicesKeyType.get(
            cell?.value.toString()
          )}'  >${
            servicesKeyType.get(cell?.value.toString()) === "Presscon"
              ? "Prc"
              : servicesKeyType.get(cell?.value.toString())
          } </div>`}`;
          return (
            <div
              className='mx-auto'
              style={{
                width: "80px",
              }}
              dangerouslySetInnerHTML={{ __html: tagName }}
            />
          );
        },
      },
      {
        Header: t("inquiry.Inquiry_type"),
        accessor: "inquiry_category",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.detail"),
        accessor: "description",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <div
              className='w-100 text-center h-100 cursor-pointer'
              style={{
                minWidth: "150px",
              }}
              onClick={() => {
                setSelectedRowID(cell?.row?.original?.id);
              }}
            >
              {cell?.value ? (
                <p
                  onClick={() => {
                    setSelectedRowID(cell?.row?.original?.id);
                  }}
                  className='text-decoration-underline cursor-pointer mb-0'
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "150px",
                  }}
                >
                  {cell?.row?.original?.inquiry_category?.code === "domain_management"
                    ? `${t("inquiry.FORM.DOMAIN.LABEL")}: ${
                        cell?.row?.original?.details?.domain_purchaser
                      }`
                    : cell.value}
                </p>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        Header: t("inquiry.Media_Company_Name"),
        accessor: "company",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Author"),
        accessor: "created_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "150px",
            }}
          >
            {cell?.value?.name || "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Created_At"),
        accessor: "created_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid() ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss") : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Status"),
        accessor: "status",
        thClass: "text-center",
        Cell: (cell: any) => {
          const statusOptions = new Map(INQUIRY_STATUS.map((i) => [i.value, i.label]));
          return (
            <p className='mb-0' style={{ width: "100px" }}>
              {t(statusOptions.get(cell?.value.toString()) || "-")}
            </p>
          );
        },
      },
      {
        Header: t("inquiry.Completed_At"),
        accessor: "reply_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid() ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss") : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.Deduction_points"),
        accessor: "deduction_point",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p className='mb-0' style={{ width: "100px" }}>
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.CS_Manager"),
        accessor: "managed_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p className='mb-0' style={{ width: "100px" }}>
            {cell?.value?.name || "-"}
          </p>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const handleGetMedia = async (id: string) => {
    const [err, result] = await catchAsync(mediaApi.getMediaByID(id), setLoadingMedia);
    setMedia(result.data);

    if (err) {
      toast.error(err);
      return;
    }
  };

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      search: isChangeKeyword ? keyword : queryParams?.search,
      service_type: formatSearch?.value ? formatSearch?.value : queryParams?.service_type,
      sort_by: sortBy?.value ? sortBy?.value : queryParams?.sort_by,
      created_from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      created_to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    });
  };

  const submitReply = async (newRequest?: {
    reply?: string;
    files?: any;
    deduction_point?: string | number;
  }) => {
    if (!inquiryDetail?.id) {
      setLoadingReply(false);
      return;
    }
    const [err, result] = await catchAsync(
      inquiryApi.updateReply({
        id: inquiryDetail?.id,
        body: {
          reply: textReply,
          files: choseFile,
          deduction_point: deductPoints || 0,
          ...newRequest,
        },
      }),
      setLoadingReply
    );

    if (result?.statusCode === 200) {
      toast("success", CONFIG_OPTION_TOAST_NORMAL);
      onCloseClick();
      getList();
      getSummaries();
      setDeductPoints("0");
    }

    if (err === "406") {
      setModalPoint(true);
      return;
    }

    if (err) {
      toast.error(err);
      return;
    }
  };

  const getSummaries = async () => {
    const [err, result] = await catchAsync(
      inquiryApi.getSummaries({ service_type: "0" }),
      setLoadingSummaries
    );
    if (err) {
      toast.error(err);
      return;
    }
    setSummaries(result?.data);
  };

  const getTotalWaiting = async () => {
    const [err, result] = await catchAsync(inquiryApi.getTotalWaiting({}), setLoadingTotalWaiting);
    if (err) {
      toast.error(err);
      return;
    }
    sesTotalWaiting(result?.data);
  };

  const getInquiryDetail = async () => {
    const [err, result] = await catchAsync(
      inquiryApi.getDetail(selectedRowID, {}),
      setLoadingDetail
    );
    if (err) {
      toast.error(err);
      return;
    }
    setInquiryDetail(result?.data);
    setTextReply(result?.data?.reply || "");
    const photos: string[] = [];
    result?.data?.inquiry_reply_files.forEach((element: { id: number; path: string }) => {
      photos.push(element?.path);
    });
    setChoseFile(photos);
  };

  const getList = async () => {
    setLoading(true);
    const requestBody = {
      ...queryParams,
      service_type: queryParams?.service_type || "0",
    };

    const [err, result] = await catchAsync(inquiryApi.getList(requestBody), setLoading);
    if (err) {
      toast.error(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const changeStatusToInProgress = async () => {
    const [err] = await catchAsync(inquiryApi.updateStatusInProgress(selectedRowID), setLoading);
    if (err) {
      toast.error(err);
      return;
    }
    getInquiryDetail();
  };

  const changeStatusToWaiting = async () => {
    const [err] = await catchAsync(inquiryApi.updateStatusWaiting(selectedRowID), setLoading);
    if (err) {
      toast.error(err);
      return;
    }
    getInquiryDetail();
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event?.target?.files === null ||
      event.target?.files?.length === 0 ||
      event?.target?.files?.length > 5 ||
      event?.target?.files?.length + choseFile.length > 5 ||
      !AcceptFileType.includes(event?.target?.files?.[0]?.type)
    ) {
      return;
    }
    setChoseFile([...choseFile, ...Array.from(event.target.files)]);
    setTimeout(() => ((event.target as HTMLInputElement).value = ""), 100);
  };

  const handleRemoveImage = (index: number) => {
    const imageTmp = structuredClone(choseFile);
    imageTmp.splice(index, 1);
    setChoseFile(imageTmp);
  };

  const onCloseClick = () => {
    setInquiryDetail({});
    setSelectedRowID("");
    setChoseFile([]);
    setTextReply("");
    setIsEdit(false);
    getList();
    setIsDeductPoints(false);
    setDeductPoints("0");
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("All Type"),
      value: "0",
    });
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const slideGallery = useMemo(() => {
    return inquiryDetail?.inquiry_files?.map((item: any) => ({
      src: item.path,
    }));
  }, [inquiryDetail?.inquiry_files]);

  const slideGalleryReply = useMemo(() => {
    return inquiryDetail?.inquiry_reply_files?.map((item: any) => ({
      src: item.path,
    }));
  }, [inquiryDetail?.inquiry_reply_files]);

  useEffect(() => {
    if (selectedRowID) {
      getInquiryDetail();
    }
  }, [selectedRowID]);

  useEffect(() => {
    searchData();
  }, [formatSearch, sortBy]);

  useEffect(() => {
    getList();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    getSummaries();
    getTotalWaiting();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title={t("inquiry.menu_title")} />
          <div className='d-flex align-items-center gap-2 mb-3 justify-content-between'>
            <div></div>
            <button
              type='button'
              className='btn btn-primary me-1'
              onClick={() => navigate(ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE)}
            >
              {`${t("inquiry.homepage_inquiry")} `}
              {loadingTotalWaiting ? <Spinner size='sm'></Spinner> : totalWaiting}
            </button>
          </div>
          <Row>
            <Col lg={12}>
              <Card id='leadsList'>
                <CardHeader className='border-0'>
                  <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                    <CardSummary
                      title='inquiry.CMS_Hub_Waiting_for_response'
                      value={summaries?.total_waiting || 0}
                      isLoading={loadingSummaries}
                    />
                  </div>
                  <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                    <div className='w-100'>
                      <div className='align-items-end'>
                        <Row>
                          <Col sm={12} md={12} lg={6} xl={6} className='mt-2 mt-md-0 mb-2 mb-md-0'>
                            <div
                              className='d-flex align-items-center date-picker-wrapper-custom'
                              style={{ gap: "40px" }}
                            >
                              <div className='fw-bold text-nowrap'>{t("inquiry.period")}</div>
                              <div style={{ width: "300px" }}>
                                <DatePickerCustom
                                  placeholder={`${t("A From Range")} - ${t("A To Range")}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  disabled={false}
                                  isClearable={true}
                                  onChangePicker={handleChangePicker}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className='g-4 align-items-center mt-0 mt-md-2'>
                          <Col sm={12} md={12} lg={4} className='mt-3 mt-md-2'>
                            <div className='d-flex align-items-center ' style={{ gap: "40px" }}>
                              <div className='fw-bold text-nowrap'>{t("inquiry.search")}</div>
                              <Input
                                type='text'
                                className='form-control search'
                                placeholder={`${t("inquiry.PlaceholderSearch")}`}
                                value={keyword}
                                onChange={(e) => {
                                  setIsChangeKeyword(true);
                                  setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            md={12}
                            lg={3}
                            className='hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2'
                          >
                            <div>
                              <button
                                type='button'
                                className='btn btn-primary me-1'
                                onClick={searchData}
                                disabled={loading}
                              >
                                <i className='ri-search-line align-bottom me-1'></i>
                                {t("Button Search")}
                              </button>
                              <button
                                type='button'
                                className='btn btn-secondary fs-14'
                                onClick={resetData}
                              >
                                <i className='ri-refresh-line align-bottom me-1'></i>
                                {t("Button Reset")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <Nav
                      className='nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0'
                      role='tablist'
                    >
                      {SERVICE_TYPE_OPTIONS_LANG.map((item, index) => (
                        <NavItem key={index}>
                          <NavLink
                            className={`cursor-pointer ${classnames({
                              active: formatSearch?.value === item?.value,
                            })}`}
                            onClick={() => {
                              setFormatSearch(item);
                            }}
                          >
                            {t(item?.label)}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <LabelWrapper isShow={false} className='w-25'>
                      <DropdownOption
                        name='sort_by'
                        dataList={SORT_BY_OPTIONS || []}
                        className='search-filter-category-type'
                        classNamePrefix='name-prefix'
                        initialValue={sortBy || null}
                        onChangeSelect={(value: Option) => setSortBy(value)}
                        // isHasOptionAll={false}
                        // optionAll={{
                        //   label: t("inquiry.AllData"),
                        //   value: "",
                        // }}
                      />
                    </LabelWrapper>
                  </div>
                </CardHeader>
                <CardBody className='pt-3'>
                  <div>
                    <TableContainer
                      className='custom-header-css'
                      divClass='table-responsive table-card'
                      tableClass='align-middle text-center'
                      theadClass='table-light text-center'
                      columns={columns}
                      data={rows || []}
                      customPageSize={pagination?.page_size}
                      customPageIndex={pagination?.page - 1}
                      totalRecords={pagination?.total}
                      customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      isLoading={loading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalDetail
        selectedRowID={selectedRowID}
        loadingDetail={loadingDetail}
        onCloseClick={onCloseClick}
        inquiryDetail={inquiryDetail}
        handleGetMedia={handleGetMedia}
        setIndex={setIndex}
        setIndexReply={setIndexReply}
        choseFile={choseFile}
        textReply={textReply}
        setTextReply={setTextReply}
        handleRemoveImage={handleRemoveImage}
        handleUploadFile={handleUploadFile}
        isDeductPoints={isDeductPoints}
        setIsDeductPoints={setIsDeductPoints}
        deductPoints={deductPoints}
        setDeductPoints={setDeductPoints}
        changeStatusToInProgress={changeStatusToInProgress}
        showModal={showModal}
        changeStatusToWaiting={changeStatusToWaiting}
        submitReply={submitReply}
        loadingReply={loadingReply}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        loadingMedia={loadingMedia}
      />
      <MediaModal
        media={media}
        onClose={() => setMedia(false)}
        onRefresh={() => {
          console.log("onRefresh");
        }}
        mode='view'
      />
      <ModalConfirmPoint
        open={modalPoint}
        onSubmit={() => {
          setModalPoint(false);
          submitReply({
            deduction_point: 0,
          });
        }}
        onClose={() => setModalPoint(false)}
      />
      <Lightbox
        index={index}
        slides={slideGallery}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Zoom]}
        render={{
          slide: ({ slide }: any) => {
            return <FileViewer width={"80%"} height={"80%"} src={slide?.src} objectFit='contain' />;
          },
        }}
      />
      <Lightbox
        index={indexReply}
        slides={slideGalleryReply}
        open={indexReply >= 0}
        close={() => setIndexReply(-1)}
        plugins={[Fullscreen, Zoom]}
        render={{
          slide: ({ slide }: any) => {
            return <FileViewer width={"80%"} height={"80%"} src={slide?.src} objectFit='contain' />;
          },
        }}
      />
    </React.Fragment>
  );
};

export default InquiryList;
