import { Option } from "api/types/_public";

export const optionsType: any = [
  { value: "gt:1-eq:1000", label: "1~1000" },
  { value: "gt:1000-eq:5000", label: "1000~5000" },
  { value: "gt:5000-eq:10000", label: "5000~10000" },
  { value: ">10000", label: "> 10000" },
];

export const optionsTypeClick: any = [
  { value: "gt:1-eq:100", label: "1~100" },
  { value: "gt:100-eq:500", label: "100~500" },
  { value: "gt:500-eq:1000", label: "500~1000" },
  { value: ">1000", label: "> 1000" },
];

export const optionsTypeRate: any = [
  { value: "gt:1-eq:10", label: "1~10" },
  { value: "gt:10-eq:50", label: "50~100" },
  { value: "gt:50-eq:100", label: "50~100" },
  { value: ">100", label: "> 100" },
];

export const typeSearch: any = [
  { value: "search_monthly", label: "Search Monthly" },
  { value: "search_monthly_pc", label: "Search Monthly PC" },
  { value: "search_monthly_mobile", label: "Search Monthly Mobile" },
  { value: "click_avg_monthly", label: "Click Avg Monthly" },
  { value: "click_avg_monthly_pc", label: "Click Avg Monthly PC" },
  { value: "click_avg_monthly_mobile", label: "Click Avg Monthly Mobile" },
  // { value: 'click_rate_monthly', label: 'Click Rate Monthly' },
  // { value: 'click_rate_monthly_pc', label: 'Click Rate Monthly PC' },
  // { value: 'click_rate_monthly_mobile', label: 'Click Rate Monthly Mobile' },
  // { value: 'impressions_avg_monthly', label: 'Impressions Avg Monthly' },
];

export const typeSearchCar: any = [
  { value: "total_sales", label: "Total sales" },
  { value: "used", label: "Local" },
  { value: "export", label: "Export" },
  { value: "manufacture", label: "Manufacture" },
];

export const typeSearchMovie: any = [
  { value: "audience", label: "Audience" },
  { value: "sales", label: "Sales" },
  { value: "screen", label: "Screen" },
];

export const typeSearchLuxury: any = [
  { value: "naver_search_monthly", label: "Naver Search" },
];

export const categoryTemplateNews: any = [{ value: "rank", label: "Rank" }];

export const typeSearchCelebrity: any = [
  { value: "naver_search_monthly", label: "Naver Search" },
];

export const typeSearchTrendIndex: any = [
  { value: "index_trend", label: "Index Trend" },
];

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  // {
  //   label: 'Except Holiday In Korea',
  //   value: 'except_holiday_in_Korea',
  // }
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_DROPDOWN: Option[] = [
  {
    label: "media.All",
    value: "",
  },
  {
    label: "media.Prc_2",
    value: "1",
  },
  {
    label: "media.PR",
    value: "2",
  },
];

export const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS: Option[] = [
  {
    label: "media.AllData",
    value: "",
  },
  {
    label: "Prc",
    value: "1",
  },
  // {
  //   label: "News ",
  //   value: "2",
  // },
  {
    label: "PR",
    value: "3",
  },
];

export const TYPE_TRANSMISSION_FORMAT_ARTICLE_STATISTICS_OPTIONS: Option[] = [
  {
    label: "media.AllData",
    value: "",
  },
  {
    label: "Prc",
    value: "1",
  },
  {
    label: "PR",
    value: "3",
  },
];

export const TYPE_TRANSMISSION_FORMAT_MEMBERSHIP_OPTIONS: Option[] = [
  {
    label: "membership.entire",
    value: "",
  },
  {
    label: "membership.basic",
    value: "basic",
  },
  {
    label: "membership.standard",
    value: "standard",
  },
  {
    label: "membership.premium",
    value: "premium",
  },
  {
    label: "membership.enterprise",
    value: "enterprise",
  },
  {
    label: "membership.special",
    value: "special",
  },
];

export const TYPE_SCRIPT_OPTIONS: {
  label: string;
  value: number;
}[] = [
  {
    label: "media.NO",
    value: 0,
  },
  {
    label: "media.SERVICE_PROVIDER",
    value: 1,
  },
  {
    label: "media.METACREW",
    value: 2,
  },
  {
    label: "media.CMS_HUB",
    value: 3,
  },
  {
    label: "media.PR_HUB",
    value: 4,
  },
  {
    label: "media.NEWS_HUB",
    value: 5,
  },
];

export const SCRIPT_TYPE = {
  NO: 0,
  SERVICE_PROVIDER: 1,
  METACREW: 2,
  CMS_HUB: 3,
  PR_HUB: 4,
  NEWS_HUB: 5,
};

export const SCRIPT_TYPE_LABEL = {
  [SCRIPT_TYPE.NO]: "스크립트 없음",
  [SCRIPT_TYPE.SERVICE_PROVIDER]: "진행 중(서비스 업체)",
  [SCRIPT_TYPE.METACREW]: "진행 중(메타크루)",
  [SCRIPT_TYPE.CMS_HUB]: "CMShub 스크립트",
  [SCRIPT_TYPE.PR_HUB]: "PRhub 스크립트",
  [SCRIPT_TYPE.NEWS_HUB]: "Newshub 스크립트",
};

export const SERVICE_TYPE = {
  CMS_HUB: 1,
  NEWS_HUB: 2,
  PR_HUB: 3,
};

export const SERVICE_TRANSMISSION_METHOD_OPTIONS: {
  label: string;
  value: number;
}[] = [
  {
    label: "",
    value: 0,
  },
  {
    label: "media.AUTO",
    value: 1,
  },
  {
    label: "media.MANUAL",
    value: 2,
  },
];

export const CATEGORY_TEMPLATE_OPTIONS: Option[] = [
  {
    label: "Rank",
    value: "rank",
  },
];

export const STATUS_CAMPAIGN_OPTIONS: Option[] = [
  {
    label: "Status_Campaign_1", // Waiting
    value: "1",
  },
  {
    label: "Status_Campaign_2", // Running
    value: "2",
  },
  {
    label: "Status_Campaign_3", // Finish
    value: "3",
  },
];

export const STATUS_CAMPAIGN_GROUP_OPTIONS: Option[] = [
  {
    label: "Status_Campaign_Group_1", // In-progress
    value: "1",
  },
  {
    label: "Status_Campaign_Group_2", // Stop
    value: "2",
  },
];

export const STATUS_RUNNING_OPTIONS: Option[] = [
  {
    label: "Status_Running_1", // running
    value: "1",
  },
  {
    label: "Status_Running_0", // stop
    value: "0",
  },
];

export const SCHEDULE_CAMPAIGN_STATISTIC_OPTIONS: Option[] = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const STATUS_CAMPAIGN = {
  WAITING: 1,
  RUNNING: 2,
  FINISH: 3,
};

export const STATUS_CAMPAIGN_GROUP = {
  IN_PROGRESS: 1, // In-progress
  STOP: 2, // Stop
};

export const ACTION_TYPE_CAMPAIGN = {
  SAVE: "save",
  POST: "post",
};

export const PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_naver",
    value: "naver",
  },
  {
    label: "Platform_google",
    value: "google",
  },
  // {
  //   label: "Platform_daum",
  //   value: "daum",
  // },
  {
    label: "Platform_etc",
    value: "etc",
  },
];

export const DEVICE_OPTIONS: Option[] = [
  {
    label: "Device_pc",
    value: "pc",
  },
  {
    label: "Device_mobile",
    value: "mobile",
  },
];

export const BOT_OPTIONS: Option[] = [
  {
    label: "Bot_0",
    value: "0",
  },
  {
    label: "Bot_1",
    value: "1",
  },
];

export const STATUS_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Status_Keyword_0", // Initial
    value: "0",
  },
  {
    label: "Status_Keyword_2", // Analyzing
    value: "2",
  },
  {
    label: "Status_Keyword_1", // Analyzed
    value: "1",
  },
];

export const STATUS_NEWS_OPTIONS: Option[] = [
  {
    label: "Status_News_0", // Initial
    value: "0",
  },
  {
    label: "Status_News_1", // Analyzed
    value: "1",
  },
  {
    label: "Status_News_5", // Warning
    value: "5",
  },
  {
    label: "Status_News_2", // Confirmed
    value: "2",
  },
  {
    label: "Status_News_3", // Sent
    value: "3",
  },
  {
    label: "Status_News_4", // Published
    value: "4",
  },
  {
    label: "Status_News_6", // Duplicate Publish
    value: "6",
  },
];

export const TYPE_NEWS_RESERVATION_OPTIONS: Option[] = [
  {
    label: "Type_News_Reservation_0", // Not Reserved News
    value: "0",
  },
  {
    label: "Type_News_Reservation_1", // Reserved News
    value: "1",
  },
];

export const TYPE_NEWS_OPTIONS: Option[] = [
  {
    label: "Type_News_1", // Trend News
    value: "1",
  },
  {
    label: "Type_News_0", // Media News
    value: "0",
  },
  {
    label: "Type_News_2", // Not News
    value: "2",
  },
];

export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export const TYPE_NEWS_FLOWED_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Type_News_Flowed_Keyword_2", // All News
    value: "2",
  },
  {
    label: "Type_News_Flowed_Keyword_1", // Trend News
    value: "1",
  },
];

export const KEYWORD_DISPLAY_FLOWED_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Keyword_Display_Flowed_Keyword_1", // Display
    value: "1",
  },
  {
    label: "Keyword_Display_Flowed_Keyword_0", // Hide
    value: "0",
  },
];

export const TYPE_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Type_Keyword_0", // Trend Keyword
    value: "0",
  },
  {
    label: "Type_Keyword_1", // Media Keyword
    value: "1",
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Chrome",
    value: "Chrome",
  },
  {
    label: "Browser_Safari",
    value: "Safari",
  },
  {
    label: "Browser_Unknown_Browser",
    value: "Unknown Browser",
  },
];

export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: "iOS",
  },
  {
    label: "OS_Mac",
    value: "Mac",
  },
  {
    label: "OS_Windows",
    value: "Windows",
  },
  {
    label: "OS_Android",
    value: "Android",
  },
  {
    label: "OS_Etc",
    value: "Etc",
  },
];

export const TYPE_STOCK_OPTIONS: Option[] = [
  {
    label: "Type_Stock_1", // kospi
    value: "1",
  },
  {
    label: "Type_Stock_2", // kosdaq
    value: "2",
  },
];

export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];

export const STATUS_CHANGE_STOCK_OPTIONS: Option[] = [
  {
    label: "Status_Change_Stock_1", // Upper limit
    value: "1",
  },
  {
    label: "Status_Change_Stock_2", // Increase
    value: "2",
  },
  {
    label: "Status_Change_Stock_3", // No change
    value: "3",
  },
  {
    label: "Status_Change_Stock_4", // Lower limit
    value: "4",
  },
  {
    label: "Status_Change_Stock_5", // Decrease
    value: "5",
  },
];

export const STATUS_RUNNING_CAMPAIGN_OPTIONS: Option[] = [
  {
    label: "Status_Action_Running_1", // Running - Activated
    value: "1",
  },
  {
    label: "Status_Action_Running_0", // Stop - Inactivated
    value: "0",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: "scroll",
  MODAL: "modal",
};

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const CLASSIFICATION_TYPE_TREND_INDEX_OPTIONS: Option[] = [
  {
    label: "Index Rank",
    value: "1",
  },
  {
    label: "Index Gender",
    value: "2",
  },
  {
    label: "Index Age",
    value: "3",
  },
];

export const CLASSIFICATION_TYPE_STOCK_OPTIONS: Option[] = [
  {
    label: "Market Capitalization",
    value: "1",
  },
  {
    label: "Current Increase Rate",
    value: "2",
  },
  {
    label: "Previous Increase Rate",
    value: "3",
  },
  {
    label: "Current Trading Volume",
    value: "4",
  },
  {
    label: "Previous Trading Volume",
    value: "5",
  },
  {
    label: "Dividend Yield",
    value: "6",
  },
  {
    label: "Foreign Net Buying Quantity",
    value: "7",
  },
  {
    label: "Foreign Net Selling Quantity",
    value: "8",
  },
  {
    label: "Institutional Net Buying Quantity",
    value: "9",
  },
  {
    label: "Institutional Net Selling Quantity",
    value: "10",
  },
];

export const STATUS_GAME_CREATE_NEWS_OPTIONS: Option[] = [
  {
    label: "Status_Game_Created_News_0", // No Create News
    value: "0",
  },
  {
    label: "Status_Game_Created_News_1", // Created News
    value: "1",
  },
];

export const STATUS_ACTIVE_SPORT_TEAM_OPTIONS: Option[] = [
  {
    label: "Status_Active_Sport_Team_0", // not active
    value: "0",
  },
  {
    label: "Status_Active_Sport_Team_1", // active
    value: "1",
  },
];

export const COLORS_STATUS_CAMPAIGN_GROUP = ["primary", "success", "danger"];

export const COLORS_STATUS_CAMPAIGN = ["info", "primary", "success", "danger"];

export const COLORS_NEWS_STATUS = [
  "danger",
  "success",
  "warning",
  "secondary",
  "primary",
  "dark",
  "info",
];

export const COLORS_STATUS_KEYWORD = ["danger", "success", "primary"];

export const COLORS_STOCK_STATUS_CHANGE = [
  "warning",
  "warning",
  "danger",
  "secondary",
  "success",
  "primary",
];

export const COLORS_SPORT_STATUS_CHANGE = [
  "warning",
  "warning",
  "danger",
  "secondary",
  "success",
  "primary",
];

export const COLORS_STATUS_ACTIVE_SPORT_TEAM = ["danger", "success", "primary"];

export const COLORS_CONFIGURATION_STATUS = ["danger", "success"];

export const COLORS_TYPE_NEWS_RESERVATION = ["success", "danger"];

export const INQUIRY_SORT_BY: Option[] = [
  {
    label: "inquiry.Newest_First",
    value: "newest",
  },
  {
    label: "inquiry.Awaiting_Reply",
    value: "waiting",
  },
  {
    label: "inquiry.In_Progress",
    value: "in_progress",
  },
  {
    label: "inquiry.Completed",
    value: "completed",
  },
];

export const MEDIA_SORT_BY: Option[] = [
  {
    label: "media.user_member",
    value: "1",
  },
  {
    label: "media.withdrawn_member",
    value: "0",
  },
];

// CREATED: 0, // WAITING
// INPROGRESS: 1,
// COMPLETED: 2,
export const INQUIRY_STATUS = [
  {
    label: "inquiry.Awaiting_Reply",
    value: "0",
  },
  {
    label: "inquiry.In_Progress",
    value: "1",
  },
  {
    label: "inquiry.Completed",
    value: "2",
  },
];

export const INQUIRY_STATUS_DETAIL_PAGE: Option[] = [
  {
    label: "inquiry.Awaiting_Reply",
    value: "0",
  },
  {
    label: "inquiry.Completed",
    value: "2",
  },
];

export const SERVICE_TYPE_OPTIONS: Option[] = [
  {
    label: "media.AllData",
    value: "0",
  },
  {
    label: "Prc",
    value: "1",
  },
  // {
  //   label: "News ",
  //   value: "2",
  // },
  {
    label: "PR",
    value: "3",
  },
];

export const PUBLIC_INQUIRY_TYPE = {
  SERVICE: 1, // 서비스 문의
  AGENCY: 2, // 대행사 문의
};

export const PUBLIC_INQUIRY_LANG = {
  [PUBLIC_INQUIRY_TYPE.SERVICE]: "inquiry.Service_Inquiry", // 서비스 문의
  [PUBLIC_INQUIRY_TYPE.AGENCY]: "inquiry.Agency_Inquiry", // 서비스 문의
};

export const BUSINESS_TYPE = {
  INDIVIDUAL: 0, // 개인
  CORPORATE: 1, // 법인
};

export const BUSINESS_LANG = {
  [BUSINESS_TYPE.INDIVIDUAL]: 'inquiry.Individual', // 서비스 문의
  [BUSINESS_TYPE.CORPORATE]: 'inquiry.Corporation', // 대행사 문의
};

export const INQUIRY_TYPE: any = {
  0: "inquiry.type.landing_service",
  1: "inquiry.type.presscon_service",
  2: "inquiry.type.presscon_agency",
  // 4: "inquiry.type.pr_general_company",
  // 5: "inquiry.type.pr_agency",
  // 6: "inquiry.type.pr_media",
};

export const SPECIAL_STATUS = {
  ACTIVED: 2, 
  INSUFFICIENT: 3, 
  CANCELLED: 4, 
}

export const BUSSINESS_TEAM = {
  NONE: 1, 
  TEAM_A: 2, 
  TEAM_B: 3, 
}

export const BUSSINESS_TEAM_LANG = {
  [BUSSINESS_TEAM.NONE]: "media.unspecified", 
  [BUSSINESS_TEAM.TEAM_A]: "media.a_team", 
  [BUSSINESS_TEAM.TEAM_B]: "media.b_team", 
}

export const MEMBERSHIP_STATUS = [
  {
    value: '1',
    label: 'membership.application',
  },
  {
    value: '2', 
    label: 'membership.activated',
  },
  {
    value: '3', 
    label: 'membership.insufficient',
  },
  {
    value: '4', 
    label: 'membership.cancelled',
  },
];
